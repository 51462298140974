<template>
  <div id="userApp-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('users.users_app') }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card> -->

    <v-card>
      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="userData && userData.role === 'A' && showExcel && hasPermission(93)"
          cols="12"
          sm="2"
          class="d-flex flex-row"
        >
          <v-btn
            color="secondary"
            block
            @click="exportExcel('/user/file/')"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="userAppListTable"
        :loading="loading"
        class="text-no-wrap"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"

        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalUsersAppListTable"
      >
        <template #[`item.username`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.username) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'views-user-app-form', params: { id: item.username, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.username }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.fullname`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.fullname) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              {{ item.fullname }}
            </div>
          </div>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveUserAppStatusVariant(item.status)"
            :class="`${resolveUserAppStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveUserAppStatusText(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(24)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>Details</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(25)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiMicrosoftExcel,
  mdiFileExcelOutline,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import exportExcel from '@core/utils/useExportExcel'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import { error } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import { validatePassword } from '@api'

import useUsersAppList from './useUsersAppList'

export default {
  setup() {
    const { userData } = useCryptoJs()
    const { t } = useUtils()
    const { router, route } = useRouter()

    const usersApp = ref([])
    const showExcel = ref(true)

    const {
      userAppListTable,
      computedTableColumns,
      totalUsersAppListTable,
      searchQuery,
      loading,
      options,
      filters,
      descSort,
      allowFetch,

      resolveUserAppStatusVariant,
      resolveUserAppStatusText,
      hasPermission,
      setFilters,
      setOptions,
      getFilterByModule,
    } = useUsersAppList()

    const onCreate = () => {
      router.push({
        name: 'views-user-app-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-user-app-form',
        params: {
          id: item.username,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-user-app-form',
        params: {
          id: item.username,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const allowExportExcel = () => {
      Swal.fire({
        title: 'Ingresar contraseña',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        input: 'password',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: async pass => {
          console.log('preConfirm')

          return validatePassword(pass)
            .then(response => {
              if (response.ok === 0) {
                error(response.message.text)
              }

              return response
            })
            .catch(e => {
              console.error('e', e)
            })
        },
      }).then(async result => {
        if (result.value && result.value.ok) {
          allowFetch.value = true
          showExcel.value = true
          Swal.close()
        } else {
          showExcel.value = false
          router.push({
            name: 'views-order-video-list',
          })
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const filtersStored = getFilterByModule('views-user-app-list')
      if (filtersStored.search) searchQuery.value = filtersStored.search

      allowExportExcel()
    })

    return {
      usersApp,
      userData,
      userAppListTable,
      computedTableColumns,
      totalUsersAppListTable,
      searchQuery,
      loading,
      options,
      filters,
      descSort,
      showExcel,

      onCreate,
      onEdit,
      onShow,
      avatarText,
      resolveUserAppStatusVariant,
      resolveUserAppStatusText,
      exportExcel,
      allowExportExcel,
      hasPermission,
      setFilters,
      setOptions,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
        mdiMicrosoftExcel,
        mdiFileExcelOutline,
        export: require('@/assets/images/svg/export.svg'),
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
