var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"userApp-list"}},[_c('v-card',[_c('v-row',{staticClass:"px-2 ma-0 text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"placeholder":_vm.t('search'),"outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-spacer'),(_vm.userData && _vm.userData.role === 'A' && _vm.showExcel && _vm.hasPermission(93))?_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"color":"secondary","block":""},on:{"click":function($event){return _vm.exportExcel('/user/file/')}}},[_c('img',{staticStyle:{"margin-right":"5px","background-position":"center center"},attrs:{"src":_vm.icons.export,"height":"20px","width":"20px","alt":"export","attrs":"export"}}),_vm._v(" "+_vm._s(_vm.t('tooltip.export'))+" ")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.computedTableColumns,"items":_vm.userAppListTable,"loading":_vm.loading,"loading-text":_vm.t('table.loading_text'),"no-data-text":_vm.t('table.no_data'),"header-props":{sortByText: _vm.t('table.sort_by')},"footer-props":{itemsPerPageText: _vm.t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]},"items-per-page":-1,"search":_vm.searchQuery,"sort-desc":_vm.descSort,"options":_vm.options,"server-items-length":_vm.totalUsersAppListTable},on:{"update:search":function($event){_vm.searchQuery=$event},"update:sortDesc":function($event){_vm.descSort=$event},"update:sort-desc":function($event){_vm.descSort=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":"primary","size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.username)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'views-user-app-form', params: { id: item.username, dataParams: item, option: 3 } }}},[_vm._v(" "+_vm._s(item.username)+" ")])],1)],1)]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":"primary","size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullname)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.fullname)+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserAppStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserAppStatusVariant(item.status)}},[_vm._v(" "+_vm._s(_vm.resolveUserAppStatusText(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(24))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"info"},on:{"click":function($event){return _vm.onShow(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Details")])]):_vm._e(),(_vm.hasPermission(25))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",attrs:{"size":"20","color":"success"},on:{"click":function($event){return _vm.onEdit(item)}}},'v-icon',attrs,false),Object.assign({}, tooltip)),[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }